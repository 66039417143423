import * as React from "react"
import '../../css/bootstrap.css';
import {ThemeProvider} from "react-bootstrap";

import "../../fonts/font-awesome/css/font-awesome.min.css";
import "../../css/template.css";
import "../../css/responsive.css";
import "../../css/base-sizing.css";
import "../../css/custom.css";
import "../../css/knowledge.css";

import HeaderAndMenu from "../../page_components/common/HeaderAndMenu";
import Footer from "../../page_components/common/Footer";
import BsHelmet from "../../page_components/common/BsHelmet";
import CommonHeroLow from "../../page_components/common/CommonHeroLow";
import BreadCrumbs from "./breadcrumbs";
import {StaticImage} from "gatsby-plugin-image";
import {useEffect} from "react";
import initStickyMenu from "../../page_components/common/initStickyMenu";


const Trading = () => {

    useEffect(() => {
        initStickyMenu();
    }, []);


    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <BsHelmet title={"BitSpreader Documentation - Trading basics"}/>
            <div id="page_wrapper">
                <HeaderAndMenu/>
                <CommonHeroLow title={"BitSpreader Documentation - Trading basics"}/>
                <BreadCrumbs link={"trading"} title="Trading basics"/>
                <section className="hg_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div id="stickymenu" className="sidebar knowledgebase">
                                    <div className="widget widget_categories">
                                        <h3 className="widgettitle title">SECTIONS</h3>
                                        <a href="#1">1. Select the spread</a>
                                        <a href="#2">2. Prepare margin</a>
                                        <a href="#3">3. Place Order</a>
                                        <a href="#4">4. Check execution</a>
                                        <a href="#5">5. Check open positions</a>
                                        <a href="#6">6. Close position</a>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-8"}>
                                <h2 id="stickyref" className="kb-article--title">
                                    <a name={"fst"}>Trading Basics</a>
                                </h2>
                                <div className={"row"}>
                                    <div className={"col-md-12 pt-10"}>
                                        <StaticImage src={"../../gatsbyimg/docs/fst.jpeg"} alt={"Crypto Futures Spread Trading Terminal"} className={"border"}/>
                                    </div>
                                </div>

                                <br/><br/>
                                <a name={"1"}><h4>1. Select the spread</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        Take a look at the documentation of the Spread Market Selector <a href={"/docs/fst#marketselector"} className={"read-more-link"}><b>HERE</b></a>
                                        <br/>
                                        <ul className="list-style1 pt-20">
                                            <li>select futures type</li>
                                            <li>select leg 1 exchange and market</li>
                                            <li>select leg 2 exchange and market</li>
                                        </ul>
                                        <br/>
                                        You can also use <a href={"/docs/fst#marketselector"} className={"read-more-link"}><b>Spread Matrix</b></a> to select the spread for trading

                                    </div>
                                    <div className={'col-md-6'}>
                                        <StaticImage src={"../../gatsbyimg/docs/marketselector.png"} alt={"Spread market selector"} className={"border"}/>
                                    </div>
                                </div>
                                <a name={"2"}><h4 className={"pt-20"}>2. Prepare funds for margin</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/>
                                        BitSpreader currently <b>does not control your margin</b> and does not do any validations against available funds before placing the spread order.<br/>
                                        <br/> Before placing an order you must assure free margin on your account.
                                        BitSpreader does not control positions that you have opened directly on the venue and doesn't check your available margin.
                                        Make sure you provide enough available funds on both markets of selected spread to cover the margin of the positions you want to open.
                                        <br/><br/>You can check available funds in the <b>Balances</b> service in BitSpreader available in left sidebar.
                                        Refer to the exchange specific documentation to understand how to make sure you have funds available for trading.
                                        <br/><br/>
                                        <ul className={"list-style1 pl-20"}>
                                            <li><a href={"/docs/apikeys#binance"}>Binance</a></li>
                                            <li><a href={"/docs/apikeys#huobi"}>Huobi</a></li>
                                            <li><a href={"/docs/apikeys#deribit"}>Deribit</a></li>
                                        </ul>
                                        <br/>
                                        <br/>
                                        In case any of the legs of the spread could not be executed due to lack of funds, you will end up with the exposure - only one leg of the spread will be traded.
                                        You can find your exposure in the <b>Account Activity>Exposure tab</b>
                                    </div>
                                </div>
                                <a name={"3"}><h4 className={"pt-20"}>3. Place order</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        Provide:
                                        <ul className="list-style1 pt-10">
                                            <li><b>size</b> - size of each outright contract</li>
                                        </ul>
                                        Spread Market Order is realised by two market orders issued by BitSpreader to the exchanges. Both market orders are sent with the same size - provided by user,
                                        but with different directions.
                                        <ul className="list-style1 pt-10">
                                            <li><b>BUY</b> spread means: BUY LEG 1, SELL LEG 2</li>
                                            <li><b>SELL</b> spread means: SELL LEG 1, BUY LEG 2</li>
                                        </ul>

                                        <br/><br/><b>Restrictions and Validation: </b> please refer to the <a href="/docs/fst/#spreaedmarketdetails" className={"read-more-link"}>Spread Market
                                        Details</a> section for reference

                                    </div>
                                    <div className={'col-md-6 text-justify'}>
                                        <StaticImage src={"../../gatsbyimg/docs/tradingpanel.png"} alt={"Spread Trading Panel"} className={"border pt-10"}/>
                                    </div>
                                </div>
                                <a name={"4"}><h4 className={"pt-20"}>4. Check execution</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        After clicking BUY/SELL you will receive stream of notifications with execution results
                                    </div>
                                    <div className={'col-md-6 text-justify'}>
                                        <StaticImage src={"../../gatsbyimg/docs/streamingconfirms.png"} alt={"Streaming execution reports"} className={"border pt-10"}/>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/>
                                        You can review the results of the individual trades in the <b>Account Activity>Execution Reports Tab</b>.
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/>
                                        <StaticImage src={"../../gatsbyimg/docs/executionreports.png"} alt={"Spread execution reports"} className={"border pt-10"}/>

                                    </div>
                                </div>
                                <a name={"5"}><h4 className={"pt-20"}>5. Check open positions</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/>
                                        <StaticImage src={"../../gatsbyimg/docs/openpositions.png"} alt={"Open spread positions"} className={"border"}/>
                                    </div>
                                </div>

                                <a name={"6"}><h4 className={"pt-20"}>6. Close Position</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/>
                                        You can close the position by clicking <b>Market</b> button next to the position - that will be equivalent to issuing the spread order with the same size as the
                                        position but opposite direction.

                                        <br/><br/>Alternatively you can manually enter the size and issue the opposite direction trade. Your position will be closed and you will be able to find it in the Closed Positions tab.
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        </ThemeProvider>
    )
}

export default Trading;
